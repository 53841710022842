var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "일반작업 상세정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업개요",
                    name: "workSummary",
                  },
                  model: {
                    value: _vm.workPermit.workSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workSummary", $$v)
                    },
                    expression: "workPermit.workSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-process", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    plantCd: _vm.workPermit.plantCd,
                    changeItem: _vm.changeItem,
                    label: "공종",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.workPermit.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "processCd", $$v)
                    },
                    expression: "workPermit.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업명",
                    name: "sopName",
                  },
                  model: {
                    value: _vm.workPermit.sopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopName", $$v)
                    },
                    expression: "workPermit.sopName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting || _vm.isVendor,
                    editable: _vm.editable,
                    plantCd: _vm.workPermit.plantCd,
                    changeItem: _vm.changeItem,
                    label: "작업업체",
                    name: "vendorCd",
                  },
                  on: { dataChange: _vm.vendorChange },
                  model: {
                    value: _vm.workPermit.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "vendorCd", $$v)
                    },
                    expression: "workPermit.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-project-location", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    plantCd: _vm.workPermit.plantCd,
                    changeItem: _vm.changeItem,
                    label: "작업장소",
                    name: "sopMapId",
                  },
                  model: {
                    value: _vm.workPermit.sopMapId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopMapId", $$v)
                    },
                    expression: "workPermit.sopMapId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "특별요구사항",
                    name: "specialRequirements",
                  },
                  model: {
                    value: _vm.workPermit.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "specialRequirements", $$v)
                    },
                    expression: "workPermit.specialRequirements",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "관련 기계/장비",
                    name: "saiContructFacilityId",
                  },
                  model: {
                    value: _vm.workPermit.saiContructFacilityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "saiContructFacilityId", $$v)
                    },
                    expression: "workPermit.saiContructFacilityId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "신청인",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_PROTECTIVE_GEAR_CD",
                    isObject: true,
                    valueText: "sopProtectiveGearName",
                    valueKey: "sopProtectiveGearCd",
                    label: "보호구",
                    name: "protectiveGears",
                  },
                  model: {
                    value: _vm.workPermit.protectiveGears,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "protectiveGears", $$v)
                    },
                    expression: "workPermit.protectiveGears",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
          [
            _c("c-table", {
              staticClass: "q-mt-md",
              attrs: {
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.workPermit.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7" },
          [
            _c(
              "c-table",
              {
                ref: "gasTable",
                staticClass: "q-mt-md",
                attrs: {
                  title: "가스농도 측정",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.workPermit.gases,
                  plantCd: _vm.workPermit.plantCd,
                  vendorCd: _vm.workPermit.vendorCd,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable && _vm.isWriting,
                  selection: "multiple",
                  rowKey: "sopGasCheckId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.isWriting
                          ? _c("c-btn", {
                              attrs: {
                                label: "추가",
                                icon: "add",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.addGas },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.isWriting
                          ? _c("c-btn", {
                              attrs: {
                                label: "제외",
                                icon: "remove",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.removeGas },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "발급 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          deptValue: "issuedDeptCd",
                          type: "dept_user",
                          label: "발급",
                          beforeText: "",
                          name: "issuedUserId",
                        },
                        model: {
                          value: _vm.workPermit.issuedUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.workPermit, "issuedUserId", $$v)
                          },
                          expression: "workPermit.issuedUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "approvalDeptCd",
                          type: "dept_user",
                          label: "승인",
                          beforeText: "",
                          name: "approvalUserId",
                        },
                        model: {
                          value: _vm.workPermit.approvalUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.workPermit, "approvalUserId", $$v)
                          },
                          expression: "workPermit.approvalUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "협조자 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "relationCooperation1DeptCd",
                          type: "dept_user",
                          label: "협조자-1",
                          beforeText: "",
                          name: "relationCooperation1UserId",
                        },
                        model: {
                          value: _vm.workPermit.relationCooperation1UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workPermit,
                              "relationCooperation1UserId",
                              $$v
                            )
                          },
                          expression: "workPermit.relationCooperation1UserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "relationCooperation2DeptCd",
                          type: "dept_user",
                          label: "협조자-2",
                          beforeText: "",
                          name: "relationCooperation2UserId",
                        },
                        model: {
                          value: _vm.workPermit.relationCooperation2UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workPermit,
                              "relationCooperation2UserId",
                              $$v
                            )
                          },
                          expression: "workPermit.relationCooperation2UserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "안전조치확인 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "maintenanceDeptCheckDeptCd",
                          type: "dept_user",
                          label: "책임자",
                          name: "maintenanceDeptCheckUserId",
                        },
                        model: {
                          value: _vm.workPermit.maintenanceDeptCheckUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workPermit,
                              "maintenanceDeptCheckUserId",
                              $$v
                            )
                          },
                          expression: "workPermit.maintenanceDeptCheckUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.workPermit,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "maintenanceDeptEntryDeptCd",
                          type: "dept_user",
                          label: "입회자",
                          name: "maintenanceDeptEntryUserId",
                        },
                        model: {
                          value: _vm.workPermit.maintenanceDeptEntryUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workPermit,
                              "maintenanceDeptEntryUserId",
                              $$v
                            )
                          },
                          expression: "workPermit.maintenanceDeptEntryUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }